import React, { useEffect, useRef, useState } from 'react'
import { Primary } from '../../components/Button'
import { DownOutlined } from "@ant-design/icons"
import {useTranslation} from "react-i18next";
import BookDemoModal from "../../containers/modals/BookDemoModal"
import { useDispatch } from 'react-redux';
import { toggleForm } from '../../redux/actions/formActions';
import { PauseOutlined, CaretRightOutlined } from "@ant-design/icons"
import YouTube, { YouTubePlayer } from "react-youtube";


const Home = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const videoRef = useRef(null)
	// const [isPlaying, setIsPlaying] = useState(false)
	const [curIndex, setCurIndex] = useState(0)
	const [curIndex2, setCurIndex2] = useState(0)
	const setIsOpen = () => dispatch(toggleForm()) 

	const opts = {
    height: "100%",
    width: "100%",
  
  };

	// const togglePlay = () => {
  //   if (videoRef.current.paused) {
	// 		videoRef.current.playVideo();
  //     setIsPlaying(true);
  //   } else {
  //     videoRef.current.pauseVideo();
  //     setIsPlaying(false);
  //   }
  // };
	const tabData = [
		{
			title: t("HOME.TABS.PUNICA_HR_TITLE"),
			text: t("HOME.TABS.PUNICA_HR_DESCRIPTION"),
		},
		{
			title: t("HOME.TABS.PUNICA_DOCS_TITLE"),
			text: t("HOME.TABS.PUNICA_DOCS_DESCRIPTION"),
		},
		{
			title: t("HOME.TABS.PUNICA_CALENDER_TITLE"),
			text: t("HOME.TABS.PUNICA_CALENDER_DESCRIPTION"),
		},
	]
	const collapsableData = [
		{
			title: t("HOME.COLLAPSABLE.TIME_AND_ATTENDANCE_TITLE"),
			description: t("HOME.COLLAPSABLE.TIME_AND_ATTENDANCE_DESCRIPTION"),
		},
		{
			title: t("HOME.COLLAPSABLE.CENTRALIZED_INFORMATION_TITLE"),
			description: t("HOME.COLLAPSABLE.CENTRALIZED_INFORMATION_DESCRIPTION"),
		},
		{
			title: t("HOME.COLLAPSABLE.AUTOMATED_OFFBOARDING_ONBOARDING_TITLE"),
			description: t("HOME.COLLAPSABLE.AUTOMATED_OFFBOARDING_ONBOARDING_DESCRIPTION"),
		},
		{
			title: t("HOME.COLLAPSABLE.RAPID_REPORTING_TITLE"),
			description: t("HOME.COLLAPSABLE.RAPID_REPORTING_DESCRIPTION"),
		},
		{
			title: t("HOME.COLLAPSABLE.SELF_SERVICE_TITLE"),
			description: t("HOME.COLLAPSABLE.SELF_SERVICE_DESCRIPTION"),
		},
		{
			title: t("HOME.COLLAPSABLE.REQUEST_MANAGEMENT_TITLE"),
			description: t("HOME.COLLAPSABLE.REQUEST_MANAGEMENT_DESCRIPTION"),
		},
		{
			title: t("HOME.COLLAPSABLE.SOCIAL_ENGAGEMENT_TITLE"),
			description: t("HOME.COLLAPSABLE.SOCIAL_ENGAGEMENT_DESCRIPTION"),
		},
		
	]

	return (
		
		<>
			<BookDemoModal />
			{/********************************** LANDING **********************************/}
			<div className='w-full max-w-[1400px] px-5 lg:px-3 flex flex-col xs:flex-row items-center mx-auto relative '>
				<div className='w-10/12 xs:w-1/2 flex flex-col gap-8 xs:gap-16 mt-20 pb-6 xs:pb-16 md:pb-32 sm:pl-15 3xl:mb-36'>
					<h5 className='title-gradient font-sans w-full lg:w-[500px] leading-[1.15] text-[1.3rem] md:text-[1.6rem] lg:text-[3rem] xl:text-6xl font-semibold pb-1 transition-all duration-300'>
						{t("HOME.MAIN_HEADER")}
					</h5>
					<p className='text-[#474747] w-full lg:w-[500px] text-[.8rem] xl:text-[1.1rem]'>
						{t("HOME.MAIN_TEXT")}
					</p>
					<span className='w-fit lg:mx-0 text-[.7rem]'>
						<Primary onClick={() => setIsOpen()}>{t("HOME.REQUEST_A_DEMO")}</Primary>
					</span>
				</div>
				<div className='xs:absolute right-0 top-[10%] md:top-0 w-[100%] xs:w-[700px] lg:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px]   xs:translate-x-[60%] xs:translate-y-[-10%] md:translate-y-0 md:translate-x-[50%] lg:translate-x-[35%] xl:translate-x-[35%] 2xl:translate-x-[25%] 3xl:translate-x-[38%] 3xl:translate-y-[-10%] '>
					<img alt='' src={ process.env.PUBLIC_URL + "/images/landing1.png"} className='  w-full h-full  z-[-10]' />
				</div>
			</div>
			{/********************************** Our Products **********************************/}
			<div id='our-products' className='w-full flex flex-col items-center md:mt-[10rem] mt-[5rem] max-w-[1400px] px-5 lg:px-3 mx-auto'>
				{/* <h5 className='title-gradient-big leading-[1.15] text-[1.8rem] lg:text-[3rem] font-semibold pb-1 transition-all duration-300 text-center mb-5'>
							{t("HOME.OUR_PRODUCTS")}
				</h5>
				<div className='w-full flex gap-5 justify-center md:justify-start'>
					<p className={`${curIndex === 0? "text-primary underline font-[500]": ""} cursor-pointer`} onClick={() => setCurIndex(0)}>{t("HOME.PUNICA_HR")}</p>
					<Tooltip title={t("HOME.COMING_SOON")}><p className={`${curIndex === 1? "text-primary underline font-[500]": ""} cursor-not-allowed opacity-40 `} 
					// onClick={()=> setCurIndex(1)}
					>{t("HOME.PUNICA_DOCS")}</p></Tooltip>
				<Tooltip title={t("HOME.COMING_SOON")}><p className={`${curIndex === 2? "text-primary underline font-[500]": ""} cursor-not-allowed opacity-40 `} 
					// onClick={()=> setCurIndex(2)}
					>{t("HOME.PUNICA_CALENDER")}</p></Tooltip>
				</div> */}

				<div className='w-full flex justify-center mt-10 xs:mb-[5rem]'>
					<div className='w-full relative  lg:h-[33rem] h-[33rem] md:h-[10rem]  mb-8'>
						{tabData.map((entry, index) => (<>
							<div key={index} className={`w-full flex justify-center absolute transition-all duration-500  pb-10
							 ${index > curIndex ? "translate-x-[100%] opacity-0" : index < curIndex ? "translate-x-[-100%] opacity-0" : ""}`} >
							<div className='w-full flex flex-col md:flex-row-reverse lg:flex-col gap-9 lg:gap-20 '>
								<div className='flex flex-col  items-center text-center md:items-start md:text-left md:w-[40%] lg:items-center lg:text-center lg:w-auto'>
									<h5 className='title-gradient-big leading-[1.15] text-[1.6rem] lg:text-[2.5rem] font-semibold pb-10 transition-all duration-300'>{entry?.title}</h5>
									<p className='text-[#474747] text-[.85rem] lg:text-[1rem] lg:px-7'>{entry?.text}</p>
								</div>
									<div className=' relative w-full md:w-2/3 lg:w-[80%] xl:w-full mx-auto aspect-square xs:aspect-[16/6.5] rounded-lg'>
										{/* <div className='absolute top-1/2 right-1/2 translate-x-[50%] translate-y-[-50%] w-[80px] h-[80px] bg-red-300 rounded-full text-white  flex justify-center items-center cursor-pointer z-[10000]' onClick={togglePlay}>
											{isPlaying? <CaretRightOutlined className='ml-2 text-[3.5rem]' /> :<PauseOutlined className='text-[2.5rem]' />}
										</div>
										<video ref={videoRef} className='w-full focus:outline-none rounded-lg ' controls>
											<source src={"/images/vvvvv.mp4"} type="video/mp4" />
										</video> */}
										<YouTube opts={opts} videoId={"comFd8WKexM"} style={{aspectRatio:"16/6.5", width:"100%", borderRadius:"10px"}} />
									</div>
							</div>		
						</div>
						</>))}
					</div>
				</div>
			</div>
				{/* -------------------------------------------------------------------------------------------------------------------------------------------- */}
				
				<div id='The ' className='w-full flex flex-col items-center pb-[12rem] md:pt-[9rem] lg:pt-[5rem] xl:pt-[15rem]'>
					<h5 className=' title-gradient-big leading-[1.15] text-[1.8rem] lg:text-[3rem] font-semibold pb-1 transition-all duration-300  text-center mb-14 px-5'>
						{t("HOME.UNITY_IN_OUR_DIVERSITY")}
					</h5>
					<p className='text-[#474747] w-full px-10 xl:w-[70%] text-center text-[.8rem] xl:text-[1.1rem] mb-20'>
							{t("HOME.UNITY_IN_OUR_DIVERSITY_DESCRIPTION")}
					</p>
					<div className='w-full max-w-[1400px] relative flex flex-col items-center mx-auto mb-5'>
						<div id="laptop" className=' md:absolute left-0 top-[10%] md:top-0 l w-[95%]   md:w-[900px] lg:w-[1000px] 2xl:w-[1100px]  md:translate-x-[-63%] lg:translate-x-[-48%] xl:translate-x-[-40%] 2xl:translate-x-[-35%] 3xl:translate-x-[-35%]
								  md:translate-y-[4%] lg:translate-y-[8%] xl:translate-y-[4%]'>
							<img alt='' src={process.env.PUBLIC_URL + "/images/laptop.png"} className='w-full h-full aspect-[16/8.4]' />
							<img alt="" className='absolute top-[2%] right-1/2 translate-x-[50%] aspect-[16/9] w-[78.5%] md:w-[82.5%] transition-opacity duration-1000 rounded-xl' style={{opacity:curIndex2 === 0 || curIndex2 > 6? "100" : "0"}} src={"/images/feature01.gif"} />
							<img alt="" className='absolute top-[2%] right-1/2 translate-x-[50%] aspect-[16/9] w-[78.5%] md:w-[82.5%] transition-opacity duration-1000 rounded-xl' style={{opacity:curIndex2 === 1? "100" : "0"}} src={"/images/feature02.gif"} />
							<img alt="" className='absolute top-[2%] right-1/2 translate-x-[50%] aspect-[16/9] w-[78.5%] md:w-[82.5%] transition-opacity duration-1000 rounded-xl' style={{opacity:curIndex2 === 2? "100" : "0"}} src={"/images/feature03.gif"} />
							<img alt="" className='absolute top-[2%] right-1/2 translate-x-[50%] aspect-[16/9] w-[78.5%] md:w-[82.5%] transition-opacity duration-1000 rounded-xl' style={{opacity:curIndex2 === 3? "100" : "0"}} src={"/images/feature04.gif"} />
							<img alt="" className='absolute top-[2%] right-1/2 translate-x-[50%] aspect-[16/9] w-[78.5%] md:w-[82.5%] transition-opacity duration-1000 rounded-xl' style={{opacity:curIndex2 === 4? "100" : "0"}} src={"/images/feature05.gif"} />
							<img alt="" className='absolute top-[2%] right-1/2 translate-x-[50%] aspect-[16/9] w-[78.5%] md:w-[82.5%] transition-opacity duration-1000 rounded-xl' style={{opacity:curIndex2 === 5? "100" : "0"}} src={"/images/feature06.gif"} />
							<img alt="" className='absolute top-[2%] right-1/2 translate-x-[50%] aspect-[16/9] w-[78.5%] md:w-[82.5%] transition-opacity duration-1000 rounded-xl' style={{opacity:curIndex2 === 6? "100" : "0"}} src={"/images/feature07.gif"} />

						</div>
				</div>
					<div className='w-full  flex flex-col md:flex-row  h-[19rem] overflow-y-visible mt-[3rem] md:mt-0 xl:mt-[3rem] mb-[9rem] sm:mb-[5rem] md:mb-20'>
					
						<div className='w-full flex flex-col items-center md:items-end md:translate-x-2 '>
							{
								collapsableData.map((entry, index) => (
								<div className={`w-[90%]   md:w-[45%] rounded-lg flex flex-col cursor-pointer pl-5 ${curIndex2 === index? "bg-[#8d0000]": "bg-[#ccc] "} my-1 py-3 transition-all duration-500`} onClick={() => {setCurIndex2(prev => prev === index? 100 : index)}}>
									<h2 className={` text-base lg:text-[1.075rem] 2xl:text-[1.2rem] items-center flex gap-4 font-semibold justify-between ${curIndex2 === index? "text-[#fff]": "text-[#000]"}`}>{entry?.title} <DownOutlined className={`mt-[.15rem] text-[.94rem] font-extrabold transition-all mr-5 duration-300 ${curIndex2 === index? "rotate-[90deg]": "rotate-[0deg]"}`} /></h2>
										<p className={`w-[70%] text-white  ${curIndex2 === index? "max-h-[100rem] transition-[max-height] ease-in-out duration-[1.5s] my-0.5": "max-h-0 transition-[max-height] overflow-hidden ease-out duration-[.45s]"} overflow-hidden text-[0.75rem] xl:text-[0.8rem] `}>
										{entry?.description}
									</p>
								</div>))
							}
						</div>

					</div>
					
				</div>
			{/* ----------------------------------------------------------------------------------------- */}
			
		</>
	)
}

export default Home