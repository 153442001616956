import React, { useState } from 'react'
import {Primary} from '../Button'
import { NavLink } from 'react-router-dom'
import {MenuOutlined} from "@ant-design/icons"
import { useTranslation } from 'react-i18next'
// import i18n from 'i18next';
// import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { toggleForm } from '../../redux/actions/formActions'

const Navbar = () => {
	// const cookie = Cookies.get("__LANG")
	const dispatch = useDispatch()
	// const [langSwitch, setLangSwitch] = useState(cookie)
	
	const [menuOpen, setMenuOpen] = useState(false)
	const { t } = useTranslation()
	
	// const setLanguage = (lang) => {
	// 	i18n.changeLanguage(lang)
	// 	.then(() => {
	// 		Cookies.set("__LANG", lang);
	// 	})
	// 	.catch((error) => {
	// 		console.log(error)
	// 	})
	// }
	return (
		<div className='w-full max-w-[1400px] mx-auto mt-3 h-[3rem] lg:h-[5rem] flex items-center justify-between  relative'>
			
			<NavLink to={'/'}><img alt='logo' src={process.env.PUBLIC_URL + "/images/punica-logo.png"}
				className='h-[2.2rem] ml-2 2xl:ml-0' /></NavLink> 

{/* <div className=' w-20 h-10 rounded-lg flex items-center justify-center text-center m-4 border border-primary overflow-hidden cursor-pointer relative top-0'>
				<div className={`w-1/2 h-full absolute bg-primary z-[-1] transition-all duration-200 ${langSwitch === "en"? "left-0" : "left-1/2"}`}></div>
				<p className={`w-1/2  ${langSwitch === "en"? "text-white" : ""}`} onClick={() => { 
					if (langSwitch !== "en") {
						setLangSwitch("en")
						setLanguage("en")
					}
				}}>
					EN
				</p>
				<p className={`w-1/2 transition-all duration-200 ${langSwitch === "tr"? "text-white" : ""}`} onClick={() => { 
					if (langSwitch !== "tr") {	
						setLangSwitch("tr")
						setLanguage("tr")
					}
				}}>
					TR
				</p>
			</div> */}
				{/* For Laptop version */}
			<div className='flex items-center justify-end gap-[2rem] lg:gap-[5rem] w-[80%] lg:pr-10 mr-2'>
				<div className='gap-4 lg:gap-12 flex-wrap flex'>
					{/* <NavLink to={'/'} style={{}} className={({isActive}) => `${isActive? "text-primary font-600" : "hover:text-[#999999]"} text-[#474747]  w-fit lg:text-[1.2rem] transition-all duration-300 truncate`}>{t("NAVBAR.PRODUCTS")}</NavLink> */}
					{/* <NavLink to={'/pricing'} className={({isActive}) => `${isActive? "text-primary font-600" : "hover:text-[#999999] "} text-[#474747] w-fit lg:text-[1.2rem] transition-all duration-300 truncate`}>{t("NAVBAR.PRICING")}</NavLink>
					<NavLink to={'/features'} className={({isActive}) => `${isActive? "text-primary font-600" : "hover:text-[#999999] "} text-[#474747] w-fit lg:text-[1.2rem] transition-all duration-300 truncate`}>{t("NAVBAR.FEATURES")}</NavLink> */}
				</div>
				<Primary  onClick={()=>dispatch(toggleForm())}>{t("NAVBAR.REQUEST_A_DEMO")}</Primary>
			</div>

			{/* For Mobile version */}
		</div>
	)
}

export default Navbar