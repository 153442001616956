import React from 'react'

const Primary = ({children, size="medium", onClick, className}) => {
	return (
		<button
			onClick={onClick}
			className={`bg-primary text-white  border-0 rounded-3xl hover:bg-primaryLight hover:text-primary hover:shadow-md
			transition-all duration-300 ${size==="medium" ? "px-4 xs:px-6  py-2 xs:py-3 md:text-[.8rem] lg:text-base" : "px-4 py-2 text-[.85rem]"} ${className}`}
		>{children}</button>
	)
}

export default Primary