

const initialState = {
	open: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state=initialState, action) => {
	switch (action.type) { 
		case "TOGGLE_FORM":
			return {
				...state,
				open: !state.open
			}
		default:
			return state
	}
}